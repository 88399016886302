
    import { Component, Prop, Vue } from "vue-property-decorator";

    @Component
    export default class Agreement extends Vue
    {
        //#region Data
        private m_HtmlAgreement: string = "";
        //#endregion

        //#region Hooks
        async mounted()
        {
            this.m_HtmlAgreement = (await this.$apiManager.setting.getTermsAsync(this.g_Language))?.terms ?? "";

            this.$nextTick(() =>
            {
                return window.print();
            });
        }
    //#endregion
    }
